export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  AppealBodyDynamicZoneInput: any;
  EventBodyDynamicZoneInput: any;
  PageBodyDynamicZoneInput: any;
  /** A string used to identify an i18n locale */
  I18NLocaleCode: any;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  total: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  pageCount: Scalars['Int'];
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  not?: InputMaybe<IdFilterInput>;
  eq?: InputMaybe<Scalars['ID']>;
  eqi?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  nei?: InputMaybe<Scalars['ID']>;
  startsWith?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  contains?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  not?: InputMaybe<BooleanFilterInput>;
  eq?: InputMaybe<Scalars['Boolean']>;
  eqi?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  nei?: InputMaybe<Scalars['Boolean']>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  contains?: InputMaybe<Scalars['Boolean']>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  not?: InputMaybe<StringFilterInput>;
  eq?: InputMaybe<Scalars['String']>;
  eqi?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nei?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  contains?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  not?: InputMaybe<IntFilterInput>;
  eq?: InputMaybe<Scalars['Int']>;
  eqi?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  nei?: InputMaybe<Scalars['Int']>;
  startsWith?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  contains?: InputMaybe<Scalars['Int']>;
  notContains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type LongFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  not?: InputMaybe<LongFilterInput>;
  eq?: InputMaybe<Scalars['Long']>;
  eqi?: InputMaybe<Scalars['Long']>;
  ne?: InputMaybe<Scalars['Long']>;
  nei?: InputMaybe<Scalars['Long']>;
  startsWith?: InputMaybe<Scalars['Long']>;
  endsWith?: InputMaybe<Scalars['Long']>;
  contains?: InputMaybe<Scalars['Long']>;
  notContains?: InputMaybe<Scalars['Long']>;
  containsi?: InputMaybe<Scalars['Long']>;
  notContainsi?: InputMaybe<Scalars['Long']>;
  gt?: InputMaybe<Scalars['Long']>;
  gte?: InputMaybe<Scalars['Long']>;
  lt?: InputMaybe<Scalars['Long']>;
  lte?: InputMaybe<Scalars['Long']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  not?: InputMaybe<FloatFilterInput>;
  eq?: InputMaybe<Scalars['Float']>;
  eqi?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  nei?: InputMaybe<Scalars['Float']>;
  startsWith?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  contains?: InputMaybe<Scalars['Float']>;
  notContains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  not?: InputMaybe<DateFilterInput>;
  eq?: InputMaybe<Scalars['Date']>;
  eqi?: InputMaybe<Scalars['Date']>;
  ne?: InputMaybe<Scalars['Date']>;
  nei?: InputMaybe<Scalars['Date']>;
  startsWith?: InputMaybe<Scalars['Date']>;
  endsWith?: InputMaybe<Scalars['Date']>;
  contains?: InputMaybe<Scalars['Date']>;
  notContains?: InputMaybe<Scalars['Date']>;
  containsi?: InputMaybe<Scalars['Date']>;
  notContainsi?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  not?: InputMaybe<DateTimeFilterInput>;
  eq?: InputMaybe<Scalars['DateTime']>;
  eqi?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  nei?: InputMaybe<Scalars['DateTime']>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  contains?: InputMaybe<Scalars['DateTime']>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  not?: InputMaybe<JsonFilterInput>;
  eq?: InputMaybe<Scalars['JSON']>;
  eqi?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  nei?: InputMaybe<Scalars['JSON']>;
  startsWith?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  contains?: InputMaybe<Scalars['JSON']>;
  notContains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
};

export type ComponentAppealsFeaturedAppeals = {
  __typename?: 'ComponentAppealsFeaturedAppeals';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  appeals?: Maybe<AppealRelationResponseCollection>;
  footerLinkText?: Maybe<Scalars['String']>;
  footerLinkHref?: Maybe<Scalars['String']>;
};


export type ComponentAppealsFeaturedAppealsAppealsArgs = {
  filters?: InputMaybe<AppealFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type ComponentChallengesFeaturedEventsChallenges = {
  __typename?: 'ComponentChallengesFeaturedEventsChallenges';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  challenges?: Maybe<ChallengeRelationResponseCollection>;
  footerLinkText?: Maybe<Scalars['String']>;
  footerLinkHref?: Maybe<Scalars['String']>;
};


export type ComponentChallengesFeaturedEventsChallengesChallengesArgs = {
  filters?: InputMaybe<ChallengeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentDonationCampaignGroupFiltersInput = {
  campaign?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentDonationCampaignGroupFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentDonationCampaignGroupFiltersInput>>>;
  not?: InputMaybe<ComponentDonationCampaignGroupFiltersInput>;
};

export type ComponentDonationCampaignGroup = {
  __typename?: 'ComponentDonationCampaignGroup';
  id: Scalars['ID'];
  campaign: Scalars['String'];
};

export enum Enum_Componentdonationquickdonateoptions_Type {
  FixedPrice = 'Fixed_Price',
  Variable = 'Variable'
}

export enum Enum_Componentdonationquickdonateoptions_Frequency {
  Single = 'single',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year'
}

export type ComponentDonationQuickDonateOptionsFiltersInput = {
  displayText?: InputMaybe<StringFilterInput>;
  price?: InputMaybe<FloatFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  frequency?: InputMaybe<StringFilterInput>;
  sfId?: InputMaybe<StringFilterInput>;
  frequencyOccurrence?: InputMaybe<IntFilterInput>;
  active?: InputMaybe<BooleanFilterInput>;
  impact?: InputMaybe<StringFilterInput>;
  maxQuantity?: InputMaybe<IntFilterInput>;
  startDate?: InputMaybe<DateFilterInput>;
  endDate?: InputMaybe<DateFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentDonationQuickDonateOptionsFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentDonationQuickDonateOptionsFiltersInput>>>;
  not?: InputMaybe<ComponentDonationQuickDonateOptionsFiltersInput>;
};

export type ComponentDonationQuickDonateOptionsInput = {
  id?: InputMaybe<Scalars['ID']>;
  displayText?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Enum_Componentdonationquickdonateoptions_Type>;
  frequency?: InputMaybe<Enum_Componentdonationquickdonateoptions_Frequency>;
  sfId?: InputMaybe<Scalars['String']>;
  frequencyOccurrence?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  impact?: InputMaybe<Scalars['String']>;
  maxQuantity?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
};

export type ComponentDonationQuickDonateOptions = {
  __typename?: 'ComponentDonationQuickDonateOptions';
  id: Scalars['ID'];
  displayText?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  type?: Maybe<Enum_Componentdonationquickdonateoptions_Type>;
  frequency?: Maybe<Enum_Componentdonationquickdonateoptions_Frequency>;
  sfId?: Maybe<Scalars['String']>;
  frequencyOccurrence?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  impact?: Maybe<Scalars['String']>;
  maxQuantity?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type ComponentDonationTeams = {
  __typename?: 'ComponentDonationTeams';
  id: Scalars['ID'];
  teams?: Maybe<FundraiseTeamCampaignRelationResponseCollection>;
  campaignList: Array<Maybe<ComponentDonationCampaignGroup>>;
};


export type ComponentDonationTeamsTeamsArgs = {
  filters?: InputMaybe<FundraiseTeamCampaignFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ComponentDonationTeamsCampaignListArgs = {
  filters?: InputMaybe<ComponentDonationCampaignGroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum Enum_Componentglobalbuttonwithtext_Btncolour {
  Yellow = 'yellow',
  Red = 'red',
  Black = 'black'
}

export type ComponentGlobalButtonWithText = {
  __typename?: 'ComponentGlobalButtonWithText';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  btnText?: Maybe<Scalars['String']>;
  btnUrl?: Maybe<Scalars['String']>;
  btnColour?: Maybe<Enum_Componentglobalbuttonwithtext_Btncolour>;
  btnOnRight?: Maybe<Scalars['Boolean']>;
};

export type ComponentGlobalCardGroup = {
  __typename?: 'ComponentGlobalCardGroup';
  id: Scalars['ID'];
  cardGroup?: Maybe<Array<Maybe<ComponentGlobalFundraisingOption>>>;
};


export type ComponentGlobalCardGroupCardGroupArgs = {
  filters?: InputMaybe<ComponentGlobalFundraisingOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentGlobalDonationQuery = {
  __typename?: 'ComponentGlobalDonationQuery';
  id: Scalars['ID'];
  query?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
};

export type ComponentGlobalEmbedContent = {
  __typename?: 'ComponentGlobalEmbedContent';
  id: Scalars['ID'];
  link: Scalars['String'];
};

export type ComponentGlobalFundraisingOptionFiltersInput = {
  title?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  btnText?: InputMaybe<StringFilterInput>;
  btnUrl?: InputMaybe<StringFilterInput>;
  showShareBtn?: InputMaybe<BooleanFilterInput>;
  showBtn?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentGlobalFundraisingOptionFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentGlobalFundraisingOptionFiltersInput>>>;
  not?: InputMaybe<ComponentGlobalFundraisingOptionFiltersInput>;
};

export type ComponentGlobalFundraisingOption = {
  __typename?: 'ComponentGlobalFundraisingOption';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<UploadFileEntityResponse>;
  btnText?: Maybe<Scalars['String']>;
  btnUrl?: Maybe<Scalars['String']>;
  showShareBtn?: Maybe<Scalars['Boolean']>;
  showBtn?: Maybe<Scalars['Boolean']>;
};

export type ComponentGlobalImageFiltersInput = {
  text?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentGlobalImageFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentGlobalImageFiltersInput>>>;
  not?: InputMaybe<ComponentGlobalImageFiltersInput>;
};

export type ComponentGlobalImage = {
  __typename?: 'ComponentGlobalImage';
  id: Scalars['ID'];
  image: UploadFileEntityResponse;
  text?: Maybe<Scalars['String']>;
};

export enum Enum_Componentglobalimages_Bg {
  Red = 'red',
  Yellow = 'yellow',
  Black = 'black'
}

export type ComponentGlobalImages = {
  __typename?: 'ComponentGlobalImages';
  id: Scalars['ID'];
  bg?: Maybe<Enum_Componentglobalimages_Bg>;
  imageList: Array<Maybe<ComponentGlobalImage>>;
};


export type ComponentGlobalImagesImageListArgs = {
  filters?: InputMaybe<ComponentGlobalImageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentGlobalLink = {
  __typename?: 'ComponentGlobalLink';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
};

export enum Enum_Componentglobalmetadata_Twittercardtype {
  Summary = 'summary',
  SummaryLargeImage = 'summary_large_image',
  App = 'app',
  Player = 'player'
}

export type ComponentGlobalMetadataFiltersInput = {
  metaTitle?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  twitterCardType?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentGlobalMetadataFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentGlobalMetadataFiltersInput>>>;
  not?: InputMaybe<ComponentGlobalMetadataFiltersInput>;
};

export type ComponentGlobalMetadataInput = {
  id?: InputMaybe<Scalars['ID']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  shareImage?: InputMaybe<Scalars['ID']>;
  twitterCardType?: InputMaybe<Enum_Componentglobalmetadata_Twittercardtype>;
};

export type ComponentGlobalMetadata = {
  __typename?: 'ComponentGlobalMetadata';
  id: Scalars['ID'];
  metaTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shareImage?: Maybe<UploadFileEntityResponse>;
  twitterCardType?: Maybe<Enum_Componentglobalmetadata_Twittercardtype>;
};

export type ComponentGlobalNewsTickerList = {
  __typename?: 'ComponentGlobalNewsTickerList';
  id: Scalars['ID'];
  news_ticker_items?: Maybe<NewsTickerRelationResponseCollection>;
};


export type ComponentGlobalNewsTickerListNews_Ticker_ItemsArgs = {
  filters?: InputMaybe<NewsTickerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type ComponentGlobalRichText = {
  __typename?: 'ComponentGlobalRichText';
  id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  anchorName?: Maybe<Scalars['String']>;
};

export type ComponentGlobalSectionHeading = {
  __typename?: 'ComponentGlobalSectionHeading';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  cardGroup?: Maybe<Array<Maybe<ComponentGlobalFundraisingOption>>>;
  footerLinkText?: Maybe<Scalars['String']>;
  footerLinkUrl?: Maybe<Scalars['String']>;
  showFooter?: Maybe<Scalars['Boolean']>;
  useSlider?: Maybe<Scalars['Boolean']>;
  showBorder?: Maybe<Scalars['Boolean']>;
  highlightBackground?: Maybe<Scalars['Boolean']>;
};


export type ComponentGlobalSectionHeadingCardGroupArgs = {
  filters?: InputMaybe<ComponentGlobalFundraisingOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentGlobalSectionWithText = {
  __typename?: 'ComponentGlobalSectionWithText';
  id: Scalars['ID'];
  section?: Maybe<Array<Maybe<ComponentGlobalTextSection>>>;
};


export type ComponentGlobalSectionWithTextSectionArgs = {
  filters?: InputMaybe<ComponentGlobalTextSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum Enum_Componentglobalspace_Height {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl'
}

export type ComponentGlobalSpace = {
  __typename?: 'ComponentGlobalSpace';
  id: Scalars['ID'];
  height: Enum_Componentglobalspace_Height;
};

export type ComponentGlobalSubscribe = {
  __typename?: 'ComponentGlobalSubscribe';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  btnText?: Maybe<Scalars['String']>;
  image?: Maybe<UploadFileEntityResponse>;
};

export enum Enum_Componentglobaltextsection_Bgcolour {
  Yellow = 'yellow',
  Black = 'black',
  Red = 'red'
}

export type ComponentGlobalTextSectionFiltersInput = {
  heading?: InputMaybe<StringFilterInput>;
  showOnRight?: InputMaybe<BooleanFilterInput>;
  btnText?: InputMaybe<StringFilterInput>;
  btnUrl?: InputMaybe<StringFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  videoLink?: InputMaybe<StringFilterInput>;
  bgColour?: InputMaybe<StringFilterInput>;
  bottomBorder?: InputMaybe<BooleanFilterInput>;
  dividerBorder?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentGlobalTextSectionFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentGlobalTextSectionFiltersInput>>>;
  not?: InputMaybe<ComponentGlobalTextSectionFiltersInput>;
};

export type ComponentGlobalTextSection = {
  __typename?: 'ComponentGlobalTextSection';
  id: Scalars['ID'];
  heading?: Maybe<Scalars['String']>;
  image?: Maybe<UploadFileEntityResponse>;
  showOnRight?: Maybe<Scalars['Boolean']>;
  btnText?: Maybe<Scalars['String']>;
  btnUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['String']>;
  bgColour?: Maybe<Enum_Componentglobaltextsection_Bgcolour>;
  bottomBorder?: Maybe<Scalars['Boolean']>;
  dividerBorder?: Maybe<Scalars['Boolean']>;
};

export type ComponentGlobalTitle = {
  __typename?: 'ComponentGlobalTitle';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  hideOnMobile?: Maybe<Scalars['Boolean']>;
};

export type ComponentGlobalYouTube = {
  __typename?: 'ComponentGlobalYouTube';
  id: Scalars['ID'];
  embedId?: Maybe<Scalars['String']>;
};

export type ComponentHeroHeroQuickDonateQuery = {
  __typename?: 'ComponentHeroHeroQuickDonateQuery';
  id: Scalars['ID'];
  Hero?: Maybe<ComponentHeroHero>;
  DonationQuery?: Maybe<ComponentGlobalDonationQuery>;
};

export type ComponentHeroHeroQuickDonate = {
  __typename?: 'ComponentHeroHeroQuickDonate';
  id: Scalars['ID'];
  hero?: Maybe<ComponentHeroHero>;
  donateOptions?: Maybe<Array<Maybe<ComponentDonationQuickDonateOptions>>>;
  zakat?: Maybe<Scalars['Boolean']>;
  sadakah?: Maybe<Scalars['Boolean']>;
  donateDescription?: Maybe<Scalars['String']>;
};


export type ComponentHeroHeroQuickDonateDonateOptionsArgs = {
  filters?: InputMaybe<ComponentDonationQuickDonateOptionsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentHeroHeroFiltersInput = {
  title?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  center?: InputMaybe<BooleanFilterInput>;
  ButtonText?: InputMaybe<StringFilterInput>;
  ButtonUrl?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentHeroHeroFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentHeroHeroFiltersInput>>>;
  not?: InputMaybe<ComponentHeroHeroFiltersInput>;
};

export type ComponentHeroHeroInput = {
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['ID']>;
  center?: InputMaybe<Scalars['Boolean']>;
  ButtonText?: InputMaybe<Scalars['String']>;
  ButtonUrl?: InputMaybe<Scalars['String']>;
};

export type ComponentHeroHero = {
  __typename?: 'ComponentHeroHero';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<UploadFileEntityResponse>;
  center?: Maybe<Scalars['Boolean']>;
  ButtonText?: Maybe<Scalars['String']>;
  ButtonUrl?: Maybe<Scalars['String']>;
};

export type ComponentShopItem = {
  __typename?: 'ComponentShopItem';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  itemImage: UploadFileEntityResponse;
  itemBtnText: Scalars['String'];
  option?: Maybe<Array<Maybe<ComponentShopOptions>>>;
};


export type ComponentShopItemOptionArgs = {
  filters?: InputMaybe<ComponentShopOptionsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentShopOptionsFiltersInput = {
  colour?: InputMaybe<StringFilterInput>;
  size?: InputMaybe<StringFilterInput>;
  pocket?: InputMaybe<BooleanFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  price?: InputMaybe<FloatFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentShopOptionsFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentShopOptionsFiltersInput>>>;
  not?: InputMaybe<ComponentShopOptionsFiltersInput>;
};

export type ComponentShopOptions = {
  __typename?: 'ComponentShopOptions';
  id: Scalars['ID'];
  colour?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  pocket?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type ComponentWrite2GovEmailsFiltersInput = {
  toEmail?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentWrite2GovEmailsFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentWrite2GovEmailsFiltersInput>>>;
  not?: InputMaybe<ComponentWrite2GovEmailsFiltersInput>;
};

export type ComponentWrite2GovEmailsInput = {
  id?: InputMaybe<Scalars['ID']>;
  toEmail?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ComponentWrite2GovEmails = {
  __typename?: 'ComponentWrite2GovEmails';
  id: Scalars['ID'];
  toEmail?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ComponentWrite2GovWriteToGov = {
  __typename?: 'ComponentWrite2GovWriteToGov';
  id: Scalars['ID'];
  write_to_gov?: Maybe<WriteToGovEntityResponse>;
};

export type UploadFileFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  alternativeText?: InputMaybe<StringFilterInput>;
  caption?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  not?: InputMaybe<UploadFileFiltersInput>;
};

export type UploadFileInput = {
  name?: InputMaybe<Scalars['String']>;
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  ext?: InputMaybe<Scalars['String']>;
  mime?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  folder?: InputMaybe<Scalars['ID']>;
  folderPath?: InputMaybe<Scalars['String']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  name: Scalars['String'];
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  ext?: Maybe<Scalars['String']>;
  mime: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UploadFile>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolderFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  not?: InputMaybe<UploadFolderFiltersInput>;
};

export type UploadFolderInput = {
  name?: InputMaybe<Scalars['String']>;
  pathId?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<Scalars['ID']>;
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  path?: InputMaybe<Scalars['String']>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  name: Scalars['String'];
  pathId: Scalars['Int'];
  parent?: Maybe<UploadFolderEntityResponse>;
  children?: Maybe<UploadFolderRelationResponseCollection>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UploadFolder>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type I18NLocaleFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<I18NLocale>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsPermissionFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  action?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsPermission>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRoleFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsRole>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  username?: InputMaybe<StringFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  password?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  blocked?: InputMaybe<BooleanFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  FirstName?: InputMaybe<StringFilterInput>;
  stripeCustomerId?: InputMaybe<StringFilterInput>;
  donations?: InputMaybe<DonationFiltersInput>;
  LastName?: InputMaybe<StringFilterInput>;
  address1?: InputMaybe<StringFilterInput>;
  address2?: InputMaybe<StringFilterInput>;
  city?: InputMaybe<StringFilterInput>;
  postcode?: InputMaybe<StringFilterInput>;
  giftaid?: InputMaybe<BooleanFilterInput>;
  phone?: InputMaybe<LongFilterInput>;
  fundraise_teams?: InputMaybe<FundraiseTeamFiltersInput>;
  fundraise_team_admins?: InputMaybe<FundraiseTeamFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsUserInput = {
  username?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['ID']>;
  FirstName?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['ID']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  donations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  LastName?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  giftaid?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['Long']>;
  fundraise_teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  fundraise_team_admins?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  username: Scalars['String'];
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  FirstName?: Maybe<Scalars['String']>;
  photo?: Maybe<UploadFileEntityResponse>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  donations?: Maybe<DonationRelationResponseCollection>;
  LastName?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  giftaid?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Long']>;
  fundraise_teams?: Maybe<FundraiseTeamRelationResponseCollection>;
  fundraise_team_admins?: Maybe<FundraiseTeamRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UsersPermissionsUserDonationsArgs = {
  filters?: InputMaybe<DonationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserFundraise_TeamsArgs = {
  filters?: InputMaybe<FundraiseTeamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserFundraise_Team_AdminsArgs = {
  filters?: InputMaybe<FundraiseTeamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsUser>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type AppealBodyDynamicZone = ComponentGlobalEmbedContent | ComponentGlobalRichText | ComponentGlobalSectionHeading | ComponentGlobalSectionWithText | ComponentGlobalSubscribe | ComponentGlobalTextSection | ComponentGlobalTitle | ComponentHeroHero | ComponentGlobalYouTube | ComponentGlobalImages | ComponentGlobalNewsTickerList | ComponentGlobalSpace | Error;

export type AppealFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  shortDescription?: InputMaybe<StringFilterInput>;
  metadata?: InputMaybe<ComponentGlobalMetadataFiltersInput>;
  zakat?: InputMaybe<BooleanFilterInput>;
  sadakah?: InputMaybe<BooleanFilterInput>;
  region?: InputMaybe<StringFilterInput>;
  category?: InputMaybe<StringFilterInput>;
  donateOptions?: InputMaybe<ComponentDonationQuickDonateOptionsFiltersInput>;
  hideFromProjectSelections?: InputMaybe<BooleanFilterInput>;
  hideFromFundraising?: InputMaybe<BooleanFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  sfUpsellProjectId?: InputMaybe<StringFilterInput>;
  sfId?: InputMaybe<StringFilterInput>;
  sadakahJariya?: InputMaybe<BooleanFilterInput>;
  event?: InputMaybe<EventFiltersInput>;
  showTitle?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<AppealFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<AppealFiltersInput>>>;
  not?: InputMaybe<AppealFiltersInput>;
};

export type AppealInput = {
  title?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<ComponentGlobalMetadataInput>;
  zakat?: InputMaybe<Scalars['Boolean']>;
  sadakah?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['ID']>;
  donateOptions?: InputMaybe<Array<InputMaybe<ComponentDonationQuickDonateOptionsInput>>>;
  hideFromProjectSelections?: InputMaybe<Scalars['Boolean']>;
  hideFromFundraising?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  sfUpsellProjectId?: InputMaybe<Scalars['String']>;
  sfId?: InputMaybe<Scalars['String']>;
  sadakahJariya?: InputMaybe<Scalars['Boolean']>;
  event?: InputMaybe<Scalars['ID']>;
  body?: InputMaybe<Array<Scalars['AppealBodyDynamicZoneInput']>>;
  showTitle?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Appeal = {
  __typename?: 'Appeal';
  title: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  metadata?: Maybe<ComponentGlobalMetadata>;
  zakat?: Maybe<Scalars['Boolean']>;
  sadakah?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  image?: Maybe<UploadFileEntityResponse>;
  donateOptions?: Maybe<Array<Maybe<ComponentDonationQuickDonateOptions>>>;
  hideFromProjectSelections?: Maybe<Scalars['Boolean']>;
  hideFromFundraising?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sfUpsellProjectId?: Maybe<Scalars['String']>;
  sfId?: Maybe<Scalars['String']>;
  sadakahJariya?: Maybe<Scalars['Boolean']>;
  event?: Maybe<EventEntityResponse>;
  body?: Maybe<Array<Maybe<AppealBodyDynamicZone>>>;
  showTitle?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type AppealDonateOptionsArgs = {
  filters?: InputMaybe<ComponentDonationQuickDonateOptionsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AppealEntity = {
  __typename?: 'AppealEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Appeal>;
};

export type AppealEntityResponse = {
  __typename?: 'AppealEntityResponse';
  data?: Maybe<AppealEntity>;
};

export type AppealEntityResponseCollection = {
  __typename?: 'AppealEntityResponseCollection';
  data: Array<AppealEntity>;
  meta: ResponseCollectionMeta;
};

export type AppealRelationResponseCollection = {
  __typename?: 'AppealRelationResponseCollection';
  data: Array<AppealEntity>;
};

export type ChallengeFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  shortDescription?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  meta?: InputMaybe<ComponentGlobalMetadataFiltersInput>;
  date?: InputMaybe<DateFilterInput>;
  location?: InputMaybe<StringFilterInput>;
  body?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ChallengeFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ChallengeFiltersInput>>>;
  not?: InputMaybe<ChallengeFiltersInput>;
};

export type ChallengeInput = {
  title?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<ComponentGlobalMetadataInput>;
  date?: InputMaybe<Scalars['Date']>;
  location?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['ID']>;
};

export type Challenge = {
  __typename?: 'Challenge';
  title: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meta?: Maybe<ComponentGlobalMetadata>;
  date?: Maybe<Scalars['Date']>;
  location?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  image?: Maybe<UploadFileEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChallengeEntity = {
  __typename?: 'ChallengeEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Challenge>;
};

export type ChallengeEntityResponse = {
  __typename?: 'ChallengeEntityResponse';
  data?: Maybe<ChallengeEntity>;
};

export type ChallengeEntityResponseCollection = {
  __typename?: 'ChallengeEntityResponseCollection';
  data: Array<ChallengeEntity>;
  meta: ResponseCollectionMeta;
};

export type ChallengeRelationResponseCollection = {
  __typename?: 'ChallengeRelationResponseCollection';
  data: Array<ChallengeEntity>;
};

export type DonationFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  stripe?: InputMaybe<JsonFilterInput>;
  amount?: InputMaybe<FloatFilterInput>;
  sfProjectId?: InputMaybe<StringFilterInput>;
  sfVariationId?: InputMaybe<StringFilterInput>;
  intention?: InputMaybe<StringFilterInput>;
  interval?: InputMaybe<StringFilterInput>;
  fundraise_page?: InputMaybe<FundraisePageFiltersInput>;
  users_permissions_user?: InputMaybe<UsersPermissionsUserFiltersInput>;
  appeal?: InputMaybe<AppealFiltersInput>;
  payment_status?: InputMaybe<StringFilterInput>;
  payment_intent?: InputMaybe<StringFilterInput>;
  donorName?: InputMaybe<StringFilterInput>;
  message?: InputMaybe<StringFilterInput>;
  includeName?: InputMaybe<BooleanFilterInput>;
  includeDonationAmount?: InputMaybe<BooleanFilterInput>;
  stripeCustomerId?: InputMaybe<StringFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  giftaid?: InputMaybe<BooleanFilterInput>;
  isUpsell?: InputMaybe<BooleanFilterInput>;
  event_attendees?: InputMaybe<EventAttendeeFiltersInput>;
  subscriptionId?: InputMaybe<StringFilterInput>;
  offline?: InputMaybe<BooleanFilterInput>;
  donationDate?: InputMaybe<DateTimeFilterInput>;
  cashReceived?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<DonationFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<DonationFiltersInput>>>;
  not?: InputMaybe<DonationFiltersInput>;
};

export type DonationInput = {
  stripe?: InputMaybe<Scalars['JSON']>;
  amount?: InputMaybe<Scalars['Float']>;
  sfProjectId?: InputMaybe<Scalars['String']>;
  sfVariationId?: InputMaybe<Scalars['String']>;
  intention?: InputMaybe<Scalars['String']>;
  interval?: InputMaybe<Scalars['String']>;
  fundraise_page?: InputMaybe<Scalars['ID']>;
  users_permissions_user?: InputMaybe<Scalars['ID']>;
  appeal?: InputMaybe<Scalars['ID']>;
  payment_status?: InputMaybe<Scalars['String']>;
  payment_intent?: InputMaybe<Scalars['String']>;
  donorName?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  includeName?: InputMaybe<Scalars['Boolean']>;
  includeDonationAmount?: InputMaybe<Scalars['Boolean']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  giftaid?: InputMaybe<Scalars['Boolean']>;
  isUpsell?: InputMaybe<Scalars['Boolean']>;
  event_attendees?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  subscriptionId?: InputMaybe<Scalars['String']>;
  offline?: InputMaybe<Scalars['Boolean']>;
  donationDate?: InputMaybe<Scalars['DateTime']>;
  cashReceived?: InputMaybe<Scalars['Boolean']>;
};

export type Donation = {
  __typename?: 'Donation';
  stripe?: Maybe<Scalars['JSON']>;
  amount?: Maybe<Scalars['Float']>;
  sfProjectId?: Maybe<Scalars['String']>;
  sfVariationId?: Maybe<Scalars['String']>;
  intention?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
  fundraise_page?: Maybe<FundraisePageEntityResponse>;
  users_permissions_user?: Maybe<UsersPermissionsUserEntityResponse>;
  appeal?: Maybe<AppealEntityResponse>;
  payment_status?: Maybe<Scalars['String']>;
  payment_intent?: Maybe<Scalars['String']>;
  donorName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  includeName?: Maybe<Scalars['Boolean']>;
  includeDonationAmount?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  giftaid?: Maybe<Scalars['Boolean']>;
  isUpsell?: Maybe<Scalars['Boolean']>;
  event_attendees?: Maybe<EventAttendeeRelationResponseCollection>;
  subscriptionId?: Maybe<Scalars['String']>;
  offline?: Maybe<Scalars['Boolean']>;
  donationDate?: Maybe<Scalars['DateTime']>;
  cashReceived?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type DonationEvent_AttendeesArgs = {
  filters?: InputMaybe<EventAttendeeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DonationEntity = {
  __typename?: 'DonationEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Donation>;
};

export type DonationEntityResponse = {
  __typename?: 'DonationEntityResponse';
  data?: Maybe<DonationEntity>;
};

export type DonationEntityResponseCollection = {
  __typename?: 'DonationEntityResponseCollection';
  data: Array<DonationEntity>;
  meta: ResponseCollectionMeta;
};

export type DonationRelationResponseCollection = {
  __typename?: 'DonationRelationResponseCollection';
  data: Array<DonationEntity>;
};

export type EventBodyDynamicZone = ComponentGlobalCardGroup | ComponentGlobalEmbedContent | ComponentGlobalRichText | ComponentGlobalSectionHeading | ComponentGlobalSectionWithText | ComponentGlobalSubscribe | ComponentGlobalTextSection | ComponentGlobalTitle | ComponentGlobalYouTube | Error;

export type EventFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  datetime?: InputMaybe<DateTimeFilterInput>;
  eventContactName?: InputMaybe<StringFilterInput>;
  eventContactEmail?: InputMaybe<StringFilterInput>;
  appeal?: InputMaybe<AppealFiltersInput>;
  location?: InputMaybe<StringFilterInput>;
  emailConfirmationTemplateId?: InputMaybe<StringFilterInput>;
  emailListId?: InputMaybe<StringFilterInput>;
  pricesFrom?: InputMaybe<StringFilterInput>;
  hero?: InputMaybe<ComponentHeroHeroFiltersInput>;
  meta?: InputMaybe<ComponentGlobalMetadataFiltersInput>;
  venue?: InputMaybe<StringFilterInput>;
  city?: InputMaybe<StringFilterInput>;
  cost?: InputMaybe<FloatFilterInput>;
  ticketsAvailable?: InputMaybe<IntFilterInput>;
  event_attendees?: InputMaybe<EventAttendeeFiltersInput>;
  ticketName?: InputMaybe<StringFilterInput>;
  additionalEmailText?: InputMaybe<StringFilterInput>;
  emailSubjectLine?: InputMaybe<StringFilterInput>;
  customQuestion?: InputMaybe<StringFilterInput>;
  ExternalLink?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
  not?: InputMaybe<EventFiltersInput>;
};

export type EventInput = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['DateTime']>;
  eventContactName?: InputMaybe<Scalars['String']>;
  eventContactEmail?: InputMaybe<Scalars['String']>;
  appeal?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['String']>;
  emailConfirmationTemplateId?: InputMaybe<Scalars['String']>;
  emailListId?: InputMaybe<Scalars['String']>;
  pricesFrom?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Array<Scalars['EventBodyDynamicZoneInput']>>;
  hero?: InputMaybe<ComponentHeroHeroInput>;
  meta?: InputMaybe<ComponentGlobalMetadataInput>;
  venue?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  ticketsAvailable?: InputMaybe<Scalars['Int']>;
  event_attendees?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  ticketName?: InputMaybe<Scalars['String']>;
  additionalEmailText?: InputMaybe<Scalars['String']>;
  emailSubjectLine?: InputMaybe<Scalars['String']>;
  customQuestion?: InputMaybe<Scalars['String']>;
  ExternalLink?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Event = {
  __typename?: 'Event';
  name: Scalars['String'];
  slug: Scalars['String'];
  datetime: Scalars['DateTime'];
  eventContactName?: Maybe<Scalars['String']>;
  eventContactEmail?: Maybe<Scalars['String']>;
  appeal?: Maybe<AppealEntityResponse>;
  location: Scalars['String'];
  emailConfirmationTemplateId?: Maybe<Scalars['String']>;
  emailListId?: Maybe<Scalars['String']>;
  pricesFrom?: Maybe<Scalars['String']>;
  body?: Maybe<Array<Maybe<EventBodyDynamicZone>>>;
  hero?: Maybe<ComponentHeroHero>;
  meta?: Maybe<ComponentGlobalMetadata>;
  venue?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  ticketsAvailable: Scalars['Int'];
  event_attendees?: Maybe<EventAttendeeRelationResponseCollection>;
  ticketName?: Maybe<Scalars['String']>;
  additionalEmailText?: Maybe<Scalars['String']>;
  emailSubjectLine?: Maybe<Scalars['String']>;
  customQuestion?: Maybe<Scalars['String']>;
  ExternalLink?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type EventEvent_AttendeesArgs = {
  filters?: InputMaybe<EventAttendeeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EventEntity = {
  __typename?: 'EventEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Event>;
};

export type EventEntityResponse = {
  __typename?: 'EventEntityResponse';
  data?: Maybe<EventEntity>;
};

export type EventEntityResponseCollection = {
  __typename?: 'EventEntityResponseCollection';
  data: Array<EventEntity>;
  meta: ResponseCollectionMeta;
};

export type EventAttendeeFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  donation?: InputMaybe<DonationFiltersInput>;
  event?: InputMaybe<EventFiltersInput>;
  customQuestionAnswer?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<EventAttendeeFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<EventAttendeeFiltersInput>>>;
  not?: InputMaybe<EventAttendeeFiltersInput>;
};

export type EventAttendeeInput = {
  email?: InputMaybe<Scalars['String']>;
  donation?: InputMaybe<Scalars['ID']>;
  event?: InputMaybe<Scalars['ID']>;
  customQuestionAnswer?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EventAttendee = {
  __typename?: 'EventAttendee';
  email?: Maybe<Scalars['String']>;
  donation?: Maybe<DonationEntityResponse>;
  event?: Maybe<EventEntityResponse>;
  customQuestionAnswer?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventAttendeeEntity = {
  __typename?: 'EventAttendeeEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<EventAttendee>;
};

export type EventAttendeeEntityResponse = {
  __typename?: 'EventAttendeeEntityResponse';
  data?: Maybe<EventAttendeeEntity>;
};

export type EventAttendeeEntityResponseCollection = {
  __typename?: 'EventAttendeeEntityResponseCollection';
  data: Array<EventAttendeeEntity>;
  meta: ResponseCollectionMeta;
};

export type EventAttendeeRelationResponseCollection = {
  __typename?: 'EventAttendeeRelationResponseCollection';
  data: Array<EventAttendeeEntity>;
};

export type FundraisePageFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
  appeals?: InputMaybe<AppealFiltersInput>;
  goal?: InputMaybe<FloatFilterInput>;
  endDate?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  donations?: InputMaybe<DonationFiltersInput>;
  fullName?: InputMaybe<StringFilterInput>;
  campaign_member?: InputMaybe<FundraiseTeamCampaignFiltersInput>;
  campaign?: InputMaybe<FundraiseTeamCampaignFiltersInput>;
  offlineDonations?: InputMaybe<FloatFilterInput>;
  story?: InputMaybe<StringFilterInput>;
  externalId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<FundraisePageFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<FundraisePageFiltersInput>>>;
  not?: InputMaybe<FundraisePageFiltersInput>;
};

export type FundraisePageInput = {
  name?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['ID']>;
  appeals?: InputMaybe<Scalars['ID']>;
  coverPhoto?: InputMaybe<Scalars['ID']>;
  goal?: InputMaybe<Scalars['Float']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  slug?: InputMaybe<Scalars['String']>;
  donations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  fullName?: InputMaybe<Scalars['String']>;
  campaign_member?: InputMaybe<Scalars['ID']>;
  campaign?: InputMaybe<Scalars['ID']>;
  offlineDonations?: InputMaybe<Scalars['Float']>;
  story?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FundraisePage = {
  __typename?: 'FundraisePage';
  name?: Maybe<Scalars['String']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
  appeals?: Maybe<AppealEntityResponse>;
  coverPhoto?: Maybe<UploadFileEntityResponse>;
  goal?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  donations?: Maybe<DonationRelationResponseCollection>;
  fullName?: Maybe<Scalars['String']>;
  campaign_member?: Maybe<FundraiseTeamCampaignEntityResponse>;
  campaign?: Maybe<FundraiseTeamCampaignEntityResponse>;
  offlineDonations?: Maybe<Scalars['Float']>;
  story?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type FundraisePageDonationsArgs = {
  filters?: InputMaybe<DonationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FundraisePageEntity = {
  __typename?: 'FundraisePageEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<FundraisePage>;
};

export type FundraisePageEntityResponse = {
  __typename?: 'FundraisePageEntityResponse';
  data?: Maybe<FundraisePageEntity>;
};

export type FundraisePageEntityResponseCollection = {
  __typename?: 'FundraisePageEntityResponseCollection';
  data: Array<FundraisePageEntity>;
  meta: ResponseCollectionMeta;
};

export type FundraisePageRelationResponseCollection = {
  __typename?: 'FundraisePageRelationResponseCollection';
  data: Array<FundraisePageEntity>;
};

export type FundraiseTeamFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  Active?: InputMaybe<BooleanFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
  team_admins?: InputMaybe<UsersPermissionsUserFiltersInput>;
  fundraise_team_campaigns?: InputMaybe<FundraiseTeamCampaignFiltersInput>;
  aboutus?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<FundraiseTeamFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<FundraiseTeamFiltersInput>>>;
  not?: InputMaybe<FundraiseTeamFiltersInput>;
};

export type FundraiseTeamInput = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  coverPhoto?: InputMaybe<Scalars['ID']>;
  Active?: InputMaybe<Scalars['Boolean']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  team_admins?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  fundraise_team_campaigns?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  aboutus?: InputMaybe<Scalars['String']>;
};

export type FundraiseTeam = {
  __typename?: 'FundraiseTeam';
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<UploadFileEntityResponse>;
  Active?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  team_admins?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  fundraise_team_campaigns?: Maybe<FundraiseTeamCampaignRelationResponseCollection>;
  aboutus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type FundraiseTeamUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type FundraiseTeamTeam_AdminsArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type FundraiseTeamFundraise_Team_CampaignsArgs = {
  filters?: InputMaybe<FundraiseTeamCampaignFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FundraiseTeamEntity = {
  __typename?: 'FundraiseTeamEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<FundraiseTeam>;
};

export type FundraiseTeamEntityResponse = {
  __typename?: 'FundraiseTeamEntityResponse';
  data?: Maybe<FundraiseTeamEntity>;
};

export type FundraiseTeamEntityResponseCollection = {
  __typename?: 'FundraiseTeamEntityResponseCollection';
  data: Array<FundraiseTeamEntity>;
  meta: ResponseCollectionMeta;
};

export type FundraiseTeamRelationResponseCollection = {
  __typename?: 'FundraiseTeamRelationResponseCollection';
  data: Array<FundraiseTeamEntity>;
};

export type FundraiseTeamCampaignFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  campaign_page?: InputMaybe<FundraisePageFiltersInput>;
  fundraise_team?: InputMaybe<FundraiseTeamFiltersInput>;
  member_pages?: InputMaybe<FundraisePageFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  memberGoal?: InputMaybe<FloatFilterInput>;
  inviteCode?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<FundraiseTeamCampaignFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<FundraiseTeamCampaignFiltersInput>>>;
  not?: InputMaybe<FundraiseTeamCampaignFiltersInput>;
};

export type FundraiseTeamCampaignInput = {
  campaign_page?: InputMaybe<Scalars['ID']>;
  fundraise_team?: InputMaybe<Scalars['ID']>;
  member_pages?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  memberGoal?: InputMaybe<Scalars['Float']>;
  inviteCode?: InputMaybe<Scalars['String']>;
};

export type FundraiseTeamCampaign = {
  __typename?: 'FundraiseTeamCampaign';
  campaign_page?: Maybe<FundraisePageEntityResponse>;
  fundraise_team?: Maybe<FundraiseTeamEntityResponse>;
  member_pages?: Maybe<FundraisePageRelationResponseCollection>;
  name: Scalars['String'];
  memberGoal: Scalars['Float'];
  inviteCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type FundraiseTeamCampaignMember_PagesArgs = {
  filters?: InputMaybe<FundraisePageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type FundraiseTeamCampaignEntity = {
  __typename?: 'FundraiseTeamCampaignEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<FundraiseTeamCampaign>;
};

export type FundraiseTeamCampaignEntityResponse = {
  __typename?: 'FundraiseTeamCampaignEntityResponse';
  data?: Maybe<FundraiseTeamCampaignEntity>;
};

export type FundraiseTeamCampaignEntityResponseCollection = {
  __typename?: 'FundraiseTeamCampaignEntityResponseCollection';
  data: Array<FundraiseTeamCampaignEntity>;
  meta: ResponseCollectionMeta;
};

export type FundraiseTeamCampaignRelationResponseCollection = {
  __typename?: 'FundraiseTeamCampaignRelationResponseCollection';
  data: Array<FundraiseTeamCampaignEntity>;
};

export type GlobalInput = {
  meta?: InputMaybe<ComponentGlobalMetadataInput>;
  metaTitleSuffix?: InputMaybe<Scalars['String']>;
  twitterUsername?: InputMaybe<Scalars['String']>;
  defaultAppeal?: InputMaybe<Scalars['ID']>;
  leaderboardStartDate?: InputMaybe<Scalars['DateTime']>;
  isMonthlyDefaultAppealUpsell?: InputMaybe<Scalars['Boolean']>;
};

export type Global = {
  __typename?: 'Global';
  meta?: Maybe<ComponentGlobalMetadata>;
  metaTitleSuffix?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  defaultAppeal?: Maybe<AppealEntityResponse>;
  leaderboardStartDate?: Maybe<Scalars['DateTime']>;
  isMonthlyDefaultAppealUpsell: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalEntity = {
  __typename?: 'GlobalEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Global>;
};

export type GlobalEntityResponse = {
  __typename?: 'GlobalEntityResponse';
  data?: Maybe<GlobalEntity>;
};

export type NewsTickerFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  prefix?: InputMaybe<StringFilterInput>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<NewsTickerFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<NewsTickerFiltersInput>>>;
  not?: InputMaybe<NewsTickerFiltersInput>;
};

export type NewsTickerInput = {
  prefix?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewsTicker = {
  __typename?: 'NewsTicker';
  prefix?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsTickerEntity = {
  __typename?: 'NewsTickerEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<NewsTicker>;
};

export type NewsTickerEntityResponse = {
  __typename?: 'NewsTickerEntityResponse';
  data?: Maybe<NewsTickerEntity>;
};

export type NewsTickerEntityResponseCollection = {
  __typename?: 'NewsTickerEntityResponseCollection';
  data: Array<NewsTickerEntity>;
  meta: ResponseCollectionMeta;
};

export type NewsTickerRelationResponseCollection = {
  __typename?: 'NewsTickerRelationResponseCollection';
  data: Array<NewsTickerEntity>;
};

export type PageBodyDynamicZone = ComponentHeroHero | ComponentGlobalSectionHeading | ComponentGlobalSubscribe | ComponentAppealsFeaturedAppeals | ComponentChallengesFeaturedEventsChallenges | ComponentGlobalTitle | ComponentGlobalSectionWithText | ComponentGlobalRichText | ComponentGlobalEmbedContent | ComponentGlobalYouTube | ComponentHeroHeroQuickDonateQuery | ComponentDonationTeams | ComponentGlobalDonationQuery | ComponentWrite2GovWriteToGov | ComponentShopItem | ComponentGlobalSpace | ComponentHeroHeroQuickDonate | ComponentGlobalNewsTickerList | ComponentGlobalButtonWithText | ComponentGlobalImages | Error;

export type PageFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  meta?: InputMaybe<ComponentGlobalMetadataFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  sadakah?: InputMaybe<BooleanFilterInput>;
  zakat?: InputMaybe<BooleanFilterInput>;
  sfId?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  not?: InputMaybe<PageFiltersInput>;
};

export type PageInput = {
  body?: InputMaybe<Array<Scalars['PageBodyDynamicZoneInput']>>;
  meta?: InputMaybe<ComponentGlobalMetadataInput>;
  title?: InputMaybe<Scalars['String']>;
  sadakah?: InputMaybe<Scalars['Boolean']>;
  zakat?: InputMaybe<Scalars['Boolean']>;
  sfId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Page = {
  __typename?: 'Page';
  body?: Maybe<Array<Maybe<PageBodyDynamicZone>>>;
  meta?: Maybe<ComponentGlobalMetadata>;
  title: Scalars['String'];
  sadakah?: Maybe<Scalars['Boolean']>;
  zakat?: Maybe<Scalars['Boolean']>;
  sfId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type PageEntity = {
  __typename?: 'PageEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Page>;
};

export type PageEntityResponse = {
  __typename?: 'PageEntityResponse';
  data?: Maybe<PageEntity>;
};

export type PageEntityResponseCollection = {
  __typename?: 'PageEntityResponseCollection';
  data: Array<PageEntity>;
  meta: ResponseCollectionMeta;
};

export type PostFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  Title?: InputMaybe<StringFilterInput>;
  Content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<PostFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<PostFiltersInput>>>;
  not?: InputMaybe<PostFiltersInput>;
};

export type PostInput = {
  Title?: InputMaybe<Scalars['String']>;
  Content?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Post = {
  __typename?: 'Post';
  Title: Scalars['String'];
  Content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type PostEntity = {
  __typename?: 'PostEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Post>;
};

export type PostEntityResponse = {
  __typename?: 'PostEntityResponse';
  data?: Maybe<PostEntity>;
};

export type PostEntityResponseCollection = {
  __typename?: 'PostEntityResponseCollection';
  data: Array<PostEntity>;
  meta: ResponseCollectionMeta;
};

export type WriteToGovFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  emails?: InputMaybe<ComponentWrite2GovEmailsFiltersInput>;
  senderEmail?: InputMaybe<StringFilterInput>;
  body?: InputMaybe<StringFilterInput>;
  subject?: InputMaybe<StringFilterInput>;
  emailListId?: InputMaybe<StringFilterInput>;
  showProgressBar?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<WriteToGovFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<WriteToGovFiltersInput>>>;
  not?: InputMaybe<WriteToGovFiltersInput>;
};

export type WriteToGovInput = {
  name?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<InputMaybe<ComponentWrite2GovEmailsInput>>>;
  senderEmail?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  emailListId?: InputMaybe<Scalars['String']>;
  showProgressBar?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type WriteToGov = {
  __typename?: 'WriteToGov';
  name: Scalars['String'];
  emails?: Maybe<Array<Maybe<ComponentWrite2GovEmails>>>;
  senderEmail: Scalars['String'];
  body: Scalars['String'];
  subject: Scalars['String'];
  emailListId: Scalars['String'];
  showProgressBar?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type WriteToGovEmailsArgs = {
  filters?: InputMaybe<ComponentWrite2GovEmailsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WriteToGovEntity = {
  __typename?: 'WriteToGovEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<WriteToGov>;
};

export type WriteToGovEntityResponse = {
  __typename?: 'WriteToGovEntityResponse';
  data?: Maybe<WriteToGovEntity>;
};

export type WriteToGovEntityResponseCollection = {
  __typename?: 'WriteToGovEntityResponseCollection';
  data: Array<WriteToGovEntity>;
  meta: ResponseCollectionMeta;
};

export type WriteToGovEntryFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  firstName?: InputMaybe<StringFilterInput>;
  lastName?: InputMaybe<StringFilterInput>;
  phone?: InputMaybe<StringFilterInput>;
  postCode?: InputMaybe<StringFilterInput>;
  write_to_gov?: InputMaybe<WriteToGovFiltersInput>;
  sentEmail?: InputMaybe<BooleanFilterInput>;
  addToList?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<WriteToGovEntryFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<WriteToGovEntryFiltersInput>>>;
  not?: InputMaybe<WriteToGovEntryFiltersInput>;
};

export type WriteToGovEntryInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
  write_to_gov?: InputMaybe<Scalars['ID']>;
  sentEmail?: InputMaybe<Scalars['Boolean']>;
  addToList?: InputMaybe<Scalars['String']>;
};

export type WriteToGovEntry = {
  __typename?: 'WriteToGovEntry';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  write_to_gov?: Maybe<WriteToGovEntityResponse>;
  sentEmail?: Maybe<Scalars['Boolean']>;
  addToList?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WriteToGovEntryEntity = {
  __typename?: 'WriteToGovEntryEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<WriteToGovEntry>;
};

export type WriteToGovEntryEntityResponse = {
  __typename?: 'WriteToGovEntryEntityResponse';
  data?: Maybe<WriteToGovEntryEntity>;
};

export type WriteToGovEntryEntityResponseCollection = {
  __typename?: 'WriteToGovEntryEntityResponseCollection';
  data: Array<WriteToGovEntryEntity>;
  meta: ResponseCollectionMeta;
};

export type GenericMorph = ComponentAppealsFeaturedAppeals | ComponentChallengesFeaturedEventsChallenges | ComponentDonationCampaignGroup | ComponentDonationQuickDonateOptions | ComponentDonationTeams | ComponentGlobalButtonWithText | ComponentGlobalCardGroup | ComponentGlobalDonationQuery | ComponentGlobalEmbedContent | ComponentGlobalFundraisingOption | ComponentGlobalImage | ComponentGlobalImages | ComponentGlobalLink | ComponentGlobalMetadata | ComponentGlobalNewsTickerList | ComponentGlobalRichText | ComponentGlobalSectionHeading | ComponentGlobalSectionWithText | ComponentGlobalSpace | ComponentGlobalSubscribe | ComponentGlobalTextSection | ComponentGlobalTitle | ComponentGlobalYouTube | ComponentHeroHeroQuickDonateQuery | ComponentHeroHeroQuickDonate | ComponentHeroHero | ComponentShopItem | ComponentShopOptions | ComponentWrite2GovEmails | ComponentWrite2GovWriteToGov | UploadFile | UploadFolder | I18NLocale | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | Appeal | Challenge | Donation | Event | EventAttendee | FundraisePage | FundraiseTeam | FundraiseTeamCampaign | Global | NewsTicker | Page | Post | WriteToGov | WriteToGovEntry;

export type FileInfoInput = {
  name?: InputMaybe<Scalars['String']>;
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  id: Scalars['ID'];
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsMeRole>;
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsRegisterInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type NavigationItemRelated = Page;

export type NavigationItemRelatedData = {
  __typename?: 'NavigationItemRelatedData';
  id: Scalars['Int'];
  attributes?: Maybe<NavigationItemRelated>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  id: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  externalPath?: Maybe<Scalars['String']>;
  uiRouterKey: Scalars['String'];
  menuAttached: Scalars['Boolean'];
  order: Scalars['Int'];
  parent?: Maybe<NavigationItem>;
  master?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  related?: Maybe<NavigationItemRelatedData>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export enum NavigationRenderType {
  Flat = 'FLAT',
  Tree = 'TREE',
  Rfr = 'RFR'
}

export type PaginationArg = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  appeal?: Maybe<AppealEntityResponse>;
  appeals?: Maybe<AppealEntityResponseCollection>;
  challenge?: Maybe<ChallengeEntityResponse>;
  challenges?: Maybe<ChallengeEntityResponseCollection>;
  donation?: Maybe<DonationEntityResponse>;
  donations?: Maybe<DonationEntityResponseCollection>;
  event?: Maybe<EventEntityResponse>;
  events?: Maybe<EventEntityResponseCollection>;
  eventAttendee?: Maybe<EventAttendeeEntityResponse>;
  eventAttendees?: Maybe<EventAttendeeEntityResponseCollection>;
  fundraisePage?: Maybe<FundraisePageEntityResponse>;
  fundraisePages?: Maybe<FundraisePageEntityResponseCollection>;
  fundraiseTeam?: Maybe<FundraiseTeamEntityResponse>;
  fundraiseTeams?: Maybe<FundraiseTeamEntityResponseCollection>;
  fundraiseTeamCampaign?: Maybe<FundraiseTeamCampaignEntityResponse>;
  fundraiseTeamCampaigns?: Maybe<FundraiseTeamCampaignEntityResponseCollection>;
  global?: Maybe<GlobalEntityResponse>;
  newsTicker?: Maybe<NewsTickerEntityResponse>;
  newsTickers?: Maybe<NewsTickerEntityResponseCollection>;
  page?: Maybe<PageEntityResponse>;
  pages?: Maybe<PageEntityResponseCollection>;
  post?: Maybe<PostEntityResponse>;
  posts?: Maybe<PostEntityResponseCollection>;
  writeToGov?: Maybe<WriteToGovEntityResponse>;
  writeToGovs?: Maybe<WriteToGovEntityResponseCollection>;
  writeToGovEntry?: Maybe<WriteToGovEntryEntityResponse>;
  writeToGovEntries?: Maybe<WriteToGovEntryEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  renderNavigationChild: Array<Maybe<NavigationItem>>;
  renderNavigation: Array<Maybe<NavigationItem>>;
};


export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAppealArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAppealsArgs = {
  filters?: InputMaybe<AppealFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};


export type QueryChallengeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryChallengesArgs = {
  filters?: InputMaybe<ChallengeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDonationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDonationsArgs = {
  filters?: InputMaybe<DonationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryEventArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryEventsArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};


export type QueryEventAttendeeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryEventAttendeesArgs = {
  filters?: InputMaybe<EventAttendeeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryFundraisePageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFundraisePagesArgs = {
  filters?: InputMaybe<FundraisePageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};


export type QueryFundraiseTeamArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFundraiseTeamsArgs = {
  filters?: InputMaybe<FundraiseTeamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryFundraiseTeamCampaignArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFundraiseTeamCampaignsArgs = {
  filters?: InputMaybe<FundraiseTeamCampaignFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNewsTickerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryNewsTickersArgs = {
  filters?: InputMaybe<NewsTickerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};


export type QueryPageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPagesArgs = {
  filters?: InputMaybe<PageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};


export type QueryPostArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPostsArgs = {
  filters?: InputMaybe<PostFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};


export type QueryWriteToGovArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryWriteToGovsArgs = {
  filters?: InputMaybe<WriteToGovFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};


export type QueryWriteToGovEntryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryWriteToGovEntriesArgs = {
  filters?: InputMaybe<WriteToGovEntryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryRenderNavigationChildArgs = {
  id: Scalars['String'];
  childUiKey: Scalars['String'];
  type?: InputMaybe<NavigationRenderType>;
  menuOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRenderNavigationArgs = {
  navigationIdOrSlug: Scalars['String'];
  type?: InputMaybe<NavigationRenderType>;
  menuOnly?: InputMaybe<Scalars['Boolean']>;
  path?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  createAppeal?: Maybe<AppealEntityResponse>;
  updateAppeal?: Maybe<AppealEntityResponse>;
  deleteAppeal?: Maybe<AppealEntityResponse>;
  createChallenge?: Maybe<ChallengeEntityResponse>;
  updateChallenge?: Maybe<ChallengeEntityResponse>;
  deleteChallenge?: Maybe<ChallengeEntityResponse>;
  createDonation?: Maybe<DonationEntityResponse>;
  updateDonation?: Maybe<DonationEntityResponse>;
  deleteDonation?: Maybe<DonationEntityResponse>;
  createEvent?: Maybe<EventEntityResponse>;
  updateEvent?: Maybe<EventEntityResponse>;
  deleteEvent?: Maybe<EventEntityResponse>;
  createEventAttendee?: Maybe<EventAttendeeEntityResponse>;
  updateEventAttendee?: Maybe<EventAttendeeEntityResponse>;
  deleteEventAttendee?: Maybe<EventAttendeeEntityResponse>;
  createFundraisePage?: Maybe<FundraisePageEntityResponse>;
  updateFundraisePage?: Maybe<FundraisePageEntityResponse>;
  deleteFundraisePage?: Maybe<FundraisePageEntityResponse>;
  createFundraiseTeam?: Maybe<FundraiseTeamEntityResponse>;
  updateFundraiseTeam?: Maybe<FundraiseTeamEntityResponse>;
  deleteFundraiseTeam?: Maybe<FundraiseTeamEntityResponse>;
  createFundraiseTeamCampaign?: Maybe<FundraiseTeamCampaignEntityResponse>;
  updateFundraiseTeamCampaign?: Maybe<FundraiseTeamCampaignEntityResponse>;
  deleteFundraiseTeamCampaign?: Maybe<FundraiseTeamCampaignEntityResponse>;
  updateGlobal?: Maybe<GlobalEntityResponse>;
  deleteGlobal?: Maybe<GlobalEntityResponse>;
  createNewsTicker?: Maybe<NewsTickerEntityResponse>;
  updateNewsTicker?: Maybe<NewsTickerEntityResponse>;
  deleteNewsTicker?: Maybe<NewsTickerEntityResponse>;
  createPage?: Maybe<PageEntityResponse>;
  updatePage?: Maybe<PageEntityResponse>;
  deletePage?: Maybe<PageEntityResponse>;
  createPost?: Maybe<PostEntityResponse>;
  updatePost?: Maybe<PostEntityResponse>;
  deletePost?: Maybe<PostEntityResponse>;
  createWriteToGov?: Maybe<WriteToGovEntityResponse>;
  updateWriteToGov?: Maybe<WriteToGovEntityResponse>;
  deleteWriteToGov?: Maybe<WriteToGovEntityResponse>;
  createWriteToGovEntry?: Maybe<WriteToGovEntryEntityResponse>;
  updateWriteToGovEntry?: Maybe<WriteToGovEntryEntityResponse>;
  deleteWriteToGovEntry?: Maybe<WriteToGovEntryEntityResponse>;
  upload: UploadFileEntityResponse;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  updateFileInfo: UploadFileEntityResponse;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  login: UsersPermissionsLoginPayload;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationUpdateUploadFileArgs = {
  id: Scalars['ID'];
  data: UploadFileInput;
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationUpdateUploadFolderArgs = {
  id: Scalars['ID'];
  data: UploadFolderInput;
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAppealArgs = {
  data: AppealInput;
};


export type MutationUpdateAppealArgs = {
  id: Scalars['ID'];
  data: AppealInput;
};


export type MutationDeleteAppealArgs = {
  id: Scalars['ID'];
};


export type MutationCreateChallengeArgs = {
  data: ChallengeInput;
};


export type MutationUpdateChallengeArgs = {
  id: Scalars['ID'];
  data: ChallengeInput;
};


export type MutationDeleteChallengeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDonationArgs = {
  data: DonationInput;
};


export type MutationUpdateDonationArgs = {
  id: Scalars['ID'];
  data: DonationInput;
};


export type MutationDeleteDonationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEventArgs = {
  data: EventInput;
};


export type MutationUpdateEventArgs = {
  id: Scalars['ID'];
  data: EventInput;
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEventAttendeeArgs = {
  data: EventAttendeeInput;
};


export type MutationUpdateEventAttendeeArgs = {
  id: Scalars['ID'];
  data: EventAttendeeInput;
};


export type MutationDeleteEventAttendeeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFundraisePageArgs = {
  data: FundraisePageInput;
};


export type MutationUpdateFundraisePageArgs = {
  id: Scalars['ID'];
  data: FundraisePageInput;
};


export type MutationDeleteFundraisePageArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFundraiseTeamArgs = {
  data: FundraiseTeamInput;
};


export type MutationUpdateFundraiseTeamArgs = {
  id: Scalars['ID'];
  data: FundraiseTeamInput;
};


export type MutationDeleteFundraiseTeamArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFundraiseTeamCampaignArgs = {
  data: FundraiseTeamCampaignInput;
};


export type MutationUpdateFundraiseTeamCampaignArgs = {
  id: Scalars['ID'];
  data: FundraiseTeamCampaignInput;
};


export type MutationDeleteFundraiseTeamCampaignArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateGlobalArgs = {
  data: GlobalInput;
};


export type MutationCreateNewsTickerArgs = {
  data: NewsTickerInput;
};


export type MutationUpdateNewsTickerArgs = {
  id: Scalars['ID'];
  data: NewsTickerInput;
};


export type MutationDeleteNewsTickerArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePageArgs = {
  data: PageInput;
};


export type MutationUpdatePageArgs = {
  id: Scalars['ID'];
  data: PageInput;
};


export type MutationDeletePageArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePostArgs = {
  data: PostInput;
};


export type MutationUpdatePostArgs = {
  id: Scalars['ID'];
  data: PostInput;
};


export type MutationDeletePostArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWriteToGovArgs = {
  data: WriteToGovInput;
};


export type MutationUpdateWriteToGovArgs = {
  id: Scalars['ID'];
  data: WriteToGovInput;
};


export type MutationDeleteWriteToGovArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWriteToGovEntryArgs = {
  data: WriteToGovEntryInput;
};


export type MutationUpdateWriteToGovEntryArgs = {
  id: Scalars['ID'];
  data: WriteToGovEntryInput;
};


export type MutationDeleteWriteToGovEntryArgs = {
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  refId?: InputMaybe<Scalars['ID']>;
  ref?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<FileInfoInput>;
  file: Scalars['Upload'];
};


export type MutationMultipleUploadArgs = {
  refId?: InputMaybe<Scalars['ID']>;
  ref?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
  data: UsersPermissionsRoleInput;
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationUpdateUsersPermissionsUserArgs = {
  id: Scalars['ID'];
  data: UsersPermissionsUserInput;
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  code: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};
