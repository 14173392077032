// export enum DonationInterval {
//   single = 'single',
//   day = 'day',
//   week = 'week',
//   month = 'month',
//   year = 'year',

import { SUUID } from 'short-uuid';
import { Enum_Componentdonationquickdonateoptions_Frequency } from 'types/cms';

export enum EnumIntention {
  Zakat = 'Zakat',
  Sadaqa = 'Sadaqa',
  SadaqaJariya = 'Sadaqa Jariya',
}

export type StrapiUser = {
  sessionLandingPage?: string | null;
  sessionSrc?: string | null;
  sessionMedium?: string | null;
  sessionCampaign?: string | null;
  sessionRef?: string | null;
  sessionContent?: string | null;
};

export interface AttendeesType {
  event: string;
  customQuestionAnswer?: string;
  key: SUUID;
}
export interface FundraiserCheckoutType extends Partial<StripeDonation> {
  appealName?: string;
  zakatable?: boolean;
  donorName?: string;
  message?: string;
  includeName?: boolean;
  includeDonationAmount?: boolean;
  fId?: string;
  sfVariationIdMonth?: string;
  consent3rdParty?: boolean;
  campaignName?: string;
}
export interface StripeDonation {
  appealId: string;
  name: string;
  amount: number;
  id: SUUID;
  interval: Enum_Componentdonationquickdonateoptions_Frequency | string;
  quantity: number;
  sku: string;
  sfProjectId: string;
  sfVariationId: string;
  intention?: string | null;
  upsellId?: string | null;
  giftaid?: boolean;
  startDate?: string | null;
  endDate?: string | null;
}

export interface StripeDonationState {
  donations: StripeDonation[];
  hasSadakahUplift: boolean;
  hasZakahUplift: boolean;
  sidebar: boolean;
  giftaid: boolean;
}

export interface CreateFundraiserType {
  campaignId?: string;
  fullName: string;
  country: string;
  appeal: string;
  appealName?: string;
  fundraiserName: string;
  goal: number;
  endDate: string | Date;
  yourStory: string;
  teamName?: string;
  teamMemberGoal?: number;
}

export interface TeamAccountSlice {
  id?: string;
  teamName: string;
  aboutTeam?: string;
}

export interface TeamPageSlice {
  id?: string;
  appeal: string;
  teamPageName: string;
  teamPageGoal: number;
  teamMemberGoal: number;
  teamPageEndDate: string | Date;
  teamPageStory: string;
}
